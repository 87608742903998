<template>
  <div
    id="plan-material-modal"
    ref="modalRef"
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 555px">
      <div class="modal-content">
        <div class="modal-header flex-column">
          <div class="d-flex align-item-center justify-conyent-between w-100">
            <h5 class="main-form-control__title" id="staticBackdropLabel">
              {{ title ?? "Список материалов изделия" }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              :disabled="state.isFormLoading"
            ></button>
          </div>
        </div>

        <div
          v-if="!state.isDataLoading"
          class="modal-body order-tables__modal-form-wrapper scrollbar-light"
        >
          <div
            v-for="material in state.materials"
            :key="material.id"
            class="order-tables__modal-form-inner text-center"
          >
            <div class="main-form-control mb-3">
              <label class="main-form-control__label">
                <b>Наименование:</b> {{ material.title }}</label
              >
            </div>

            <div class="mb-3">
              <label class="main-form-control__label">
                <b>Кол-во:</b> {{ material.quantity ?? "0" }}
                {{ material.measurement?.title }}</label
              >
            </div>
          </div>

          <span
            v-if="!state.materials?.length"
            class="d-block text-bold text-center my-3"
            >Ничего не найдено!</span
          >
        </div>

        <div
          v-else
          class="d-flex justify-content-center align-items-center my-5"
        >
          <div class="spinner-border spinner-border-md ms-2" role="status">
            <span class="visually-hidden">Загрузка...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRef, watch } from "vue";

import { errorAlert } from "@/helpers/alerts";

export default {
  props: ["productId", "apiRequest", "title"],
  emits: ["update:productId"],
  setup(props) {
    const productId = toRef(props, "productId");
    const state = reactive({
      isDataLoading: false,
      materials: [],
    });

    watch(productId, async () => {
      if (productId.value == null || !props.apiRequest) return;

      try {
        state.isDataLoading = true;
        state.materials = await props.apiRequest(productId.value);
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      } finally {
        state.isDataLoading = false;
      }
    });

    return {
      state,
    };
  },
};
</script>