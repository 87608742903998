<template>
  <section class="depot">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="d-flex align-items-center">
        <router-link
          class="main-tab__title"
          :to="{ name: 'applications-page' }"
        >
          Заявки
        </router-link>
        <h2 class="main-tab__title active">План</h2>
      </div>
    </div>

    <div class="d-flex">
      <FixedColumnTable>
        <table class="table main-table bordered text-center">
          <thead>
            <tr>
              <th scope="col">№</th>
              <th class="text-start" scope="col">Наименование</th>
              <th scope="col">Просмотр сырья</th>
              <th scope="col">Цех</th>
              <th scope="col">Участок</th>
              <th scope="col">Склад</th>
              <th scope="col">Статус</th>
              <th scope="col">Прогресс</th>
              <th scope="col">Кол-во изделий</th>
              <th scope="col">Исполнитель</th>
              <th scope="col">Время выполнения</th>
              <th scope="col">Фактическое время выполнения</th>
              <th scope="col">Фактическое количество</th>
              <th scope="col">Кол-во брака</th>
              <th scope="col">Кол-во уценки</th>
              <th scope="col">Остаточное количество</th>
              <th scope="col">Единица измерения</th>
              <th scope="col">Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in state.applications" :key="item.id">
              <td :style="{ width: item?.id?.length }">{{ item.id }}</td>
              <td class="text-start">{{ item.product?.title ?? "-" }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-outline-gold btn-icon"
                  data-bs-toggle="modal"
                  data-bs-target="#plan-material-modal"
                  @click="productToShow = item.product?.id"
                >
                  <inline-svg
                    width="24"
                    height="24"
                    :src="require('@/assets/images/info.svg')"
                  />
                </button>
              </td>
              <td>
                {{ item.manufacture?.title ?? "-" }}
              </td>
              <td>
                {{ item.area?.title ?? "-" }}
              </td>
              <td>
                {{ item.stock?.title ?? "-" }}
              </td>
              <td>
                <span
                  class="main-circle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  :title="item.status?.id ? item.status?.title : 'Не указан'"
                  :class="
                    item.status?.id
                      ? getClassForStatus(item.status?.id)
                      : 'danger'
                  "
                ></span>
              </td>
              <td>
                <span
                  class="text-nowrap"
                  :class="{
                    'text-danger': item.progress == 0,
                    'text-warning': item.progress > 0 && item.progress < 100,
                    'text-success': item.progress == 100,
                  }"
                  >{{ item.progress ? item.progress + "%" : "0%" }}</span
                >
              </td>
              <td>
                {{ convertNumber(item.quantity) ?? "-" }}
              </td>
              <td>
                {{ item.users[0]?.name ?? "-" }}
              </td>
              <td>
                <span class="text-nowrap">{{ item.need_time ?? "-" }}</span>
              </td>
              <td>
                <span class="text-nowrap">{{ item.time ?? "-" }}</span>
              </td>
              <td>
                {{ convertNumber(item.real_quantity) ?? "-" }}
              </td>
              <td>
                {{ convertNumber(item.defect_quantity) ?? "-" }}
              </td>
              <td>
                {{ convertNumber(item.markdown_quantity) ?? "-" }}
              </td>
              <td>
                {{ convertNumber(item.rest_quantity) ?? "-" }}
              </td>
              <td>{{ item.product?.measurement?.title ?? "-" }}</td>
              <td>
                <button
                  type="button"
                  class="btn btn-gold w-100"
                  :disabled="
                    item?.status?.id != 3 ||
                    item?.is_taken ||
                    !isRedeemAccountingActive
                  "
                  @click="redeemAccountingItem(item.deal_id, item.id)"
                >
                  Забрать
                </button>
              </td>
            </tr>

            <tr v-show="!state.applications.length && !isDataLoading">
              <td colspan="9">
                <span class="d-block text-bold my-3">Ничего не найдено!</span>
              </td>
            </tr>
          </tbody>
        </table>
      </FixedColumnTable>
    </div>
    <Pagination
      v-model:currentPage="state.currentPage"
      :totalPages="state.totalPages"
    />
  </section>

  <PlanMaterialModal :productId="productToShow" :apiRequest="getPlanProducts" />
</template>

<script>
import { ref, reactive, onMounted, watch, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import { successAlert, confirmAlert } from "@/helpers/alerts";
import { convertNumber } from "@/helpers/stringUtils";
import { getClassForStatus } from "@/helpers/applicationStatus";
import { errorAlert } from "@/helpers/alerts";
import useLoadingModal from "@/mixins/useLoadingModal";
import { getDealPlanList } from "@/api/deal";
import { getPlanProducts } from "@/api/plan";
import { takeAccounting } from "@/api/accounting";

import FixedColumnTable from "@/components/ui/FixedColumnTable.vue";
import Pagination from "@/components/ui/Pagination.vue";
import PlanMaterialModal from "@/components/plan/MaterialModal.vue";

export default {
  components: {
    FixedColumnTable,
    Pagination,
    PlanMaterialModal,
  },
  setup() {
    const store = useStore();
    const userPermissions = computed(() => store.getters.getUserPermissions);
    const isRedeemAccountingActive = computed(
      () => userPermissions.value["31"]?.permissions["33"] ?? false
    );
    const route = useRoute();
    const state = reactive({
      currentPage: 1,
      totalPages: 1,
      applications: [],
      id: route.params.id,
    });
    const productToShow = ref(null);
    const { isDataLoading } = useLoadingModal();

    onMounted(() => {
      getData();
    });

    watch(
      () => state.currentPage,
      () => {
        getData();
      }
    );

    async function getData() {
      try {
        isDataLoading.value = true;
        const res = await getDealPlanList(state.id, state.currentPage);
        state.applications = res.data;
        state.totalPages = res.meta?.last_page;
      } catch {
        errorAlert("Ошибка загрузки, попробуйте еще раз!");
      } finally {
        isDataLoading.value = false;
      }
    }

    const redeemAccountingItem = (deal_id, id) => {
      confirmAlert("Подтвердить действие").then(async (result) => {
        if (result.isConfirmed) {
          try {
            isDataLoading.value = true;
            await takeAccounting(deal_id, id);
            await getData();
            successAlert("Успешно!");
          } catch {
            isDataLoading.value = false;
          }
        }
      });
    };

    return {
      isRedeemAccountingActive,
      state,
      productToShow,
      isDataLoading,
      getData,
      getClassForStatus,
      convertNumber,
      getPlanProducts,
      redeemAccountingItem,
    };
  },
};
</script>