<template>
  <div class="fixed-columns-table-wrapper w-100">
    <div class="table-responsive main-table-responsive">
      <avesomebar
        ref="scrollable2"
        class="awesome-table"
        @os-scroll="onScroll(true)"
      >
        <slot></slot>
      </avesomebar>
    </div>

    <div class="fixed-table w-100">
      <avesomebar
        ref="scrollable1"
        class="awesome-table w-100"
        :options="{
          overflow: {
            x: 'hidden',
          },
        }"
        @os-scroll="onScroll(false)"
      >
        <slot></slot>
      </avesomebar>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

import { hideDropdown } from "@/helpers/bootstrap";

export default {
  props: ["isDropdownsWatcher"],
  setup(props) {
    const isScrolling = ref(false);
    const timeout = ref(null);
    const scrollable1 = ref(null);
    const scrollable2 = ref(null);

    const onScroll = (isFixed) => {
      const scrollable1Instance = scrollable1?.value?.osInstance();
      const scrollable2Instance = scrollable2?.value?.osInstance();

      if (!scrollable1Instance || !scrollable2Instance) return;

      const { scrollOffsetElement: scrollOffsetElement1 } =
        scrollable1Instance.elements();
      const { scrollOffsetElement: scrollOffsetElement2 } =
        scrollable2Instance.elements();
      const { scrollTop: scrollTop1 } = scrollOffsetElement1;
      const { scrollTop: scrollTop2 } = scrollOffsetElement2;

      if (isFixed)
        scrollOffsetElement1.scrollTo({
          top: scrollTop2,
        });
      else
        scrollOffsetElement2.scrollTo({
          top: scrollTop1,
        });

      if (props.isDropdownsWatcher) dropdownsWatcher();
    };

    const dropdownsWatcher = () => {
      clearTimeout(timeout.value);

      if (!isScrolling.value) {
        isScrolling.value = true;
        hideDropdown();
      }

      timeout.value = setTimeout(() => {
        isScrolling.value = false;
      }, 200);
    };

    return {
      scrollable1,
      scrollable2,
      onScroll,
    };
  },
};
</script>

<style lang="scss">
.awesome-table {
  max-height: calc(100vh - 250px);
  overflow-x: hidden;

  .main-table {
    thead tr {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }
}

.fixed-columns-table-wrapper {
  position: relative;
  display: grid;
}

.fixed-table > * {
  position: absolute;
  top: 0;
  pointer-events: none;

  th,
  td {
    opacity: 0;
    pointer-events: none;
    border-color: transparent !important;
  }

  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(2),
  td:nth-child(2) {
    opacity: 1;
    pointer-events: all;
    border-color: var(--bs-border-color) !important;
  }
}
</style>