import axios from '@/api/axios';

const getAccounting = async (currentPage) => {
  const { data: response } = await axios.get('/accounting', {
    params: {
      page: currentPage,
    },
  });
  return response;
};

const redeemAccounting = async (task) => {
  const { data: response } = await axios.post(`/accounting/${task}/redeem`);
  return response.data;
};

const takeAccounting = async (id, task_id) => {
  const { data: response } = await axios.post(`deals/${id}/plans`, {
    task_id,
  });
  return response.data;
};

export { getAccounting, redeemAccounting, takeAccounting };
