import axios from '@/api/axios';
import moment from 'moment';

const getPlan = async (current_page, area_ids, manufacture_id, search_value, status) => {
  const { data: response } = await axios.get('/plan', {
    params: {
      page: current_page,
      area_ids,
      manufacture_id,
      search_value,
      status,
    },
  });
  return response;
};

const getSubtaskGroups = async (
  dealId,
  taskId,
  current_page,
  area_ids,
  manufacture_id,
  search_value,
  status,
) => {
  const { data: response } = await axios.get(`/deals/${dealId}/tasks/${taskId}/subtask`, {
    params: {
      page: current_page,
      area_ids,
      manufacture_id,
      search_value,
      status,
    },
  });
  return response;
};

const getSubtasks = async (
  taskId,
  subtaskId,
  current_page,
  area_ids,
  manufacture_id,
  search_value,
  status,
) => {
  const { data: response } = await axios.get(`/tasks/${taskId}/subtasks/${subtaskId}/group`, {
    params: {
      page: current_page,
      area_ids,
      manufacture_id,
      search_value,
      status,
    },
  });
  return response;
};

const getPlanTask = async (taskId) => {
  const { data: response } = await axios.get(`/plan/task/${taskId}`);
  return response;
};

const getPlanProducts = async (productId) => {
  const { data: response } = await axios.get(`/plan/${productId}`);
  return response.data;
};

const updateTask = async (data) => {
  const timerData = localStorage.getItem(`alem-task-${data.id}-timer`)
    ? JSON.parse(localStorage.getItem(`alem-task-${data.id}-timer`))
    : [];

  const { data: response } = await axios.put(`/plan/task/${data.id}`, {
    ...data,
    end_date_time: timerData.lastUpdated,
    time: timerData.time,
  });
  return response;
};

const updateTaskStatus = async (id, status_id) => {
  const { data: response } = await axios.put(`/plan/update-status/${id}`, { status_id });
  return response;
};

const startTask = async (id, start_date_time) => {
  const { data: response } = await axios.put(`/plan/task/${id}/start-date`, {
    start_date_time,
  });
  return response;
};

const finishTaskPartially = async (data) => {
  const { data: response } = await axios.put(`plan/task/${data.id}/partly-finish`, {
    fact_amount: data.fact_amount,
    defect_amount: data.defect_amount,
    end_date_time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
  });
  return response;
};

export {
  getPlan,
  getSubtaskGroups,
  getSubtasks,
  getPlanTask,
  getPlanProducts,
  updateTask,
  updateTaskStatus,
  startTask,
  finishTaskPartially,
};
