import axios from '@/api/axios';

const getDeals = async (currentPage, search_value, status) => {
  const { data: response } = await axios.get('/deals', {
    params: {
      page: currentPage,
      'filter[title]': search_value,
      'filter[status]': status,
    },
  });
  return response;
};

const getDealById = async (id) => {
  const { data: response } = await axios.get(`/deals/${id}/tasks`);
  const deal = response?.data[0];

  deal.tasks = deal.tasks
    .map((item) => ({
      ...item,
      measurement: item.product?.measurement,
      need_time: {
        seconds: parseInt(item.need_time?.slice(0, 2)) ?? 0, // THESE ARE DAYS NOT SECONDS!!!
        hours: parseInt(item.need_time?.slice(3, 5)) ?? 0,
        minutes: parseInt(item.need_time?.slice(6, 8)) ?? 0,
      },
      subtasks: item.subtasks
        ?.map((subitem) => ({
          ...subitem,
          measurement: subitem.product?.measurement,
          need_time: {
            seconds: parseInt(subitem.need_time?.slice(0, 2)) ?? 0, // THESE ARE DAYS NOT SECONDS!!!
            hours: parseInt(subitem.need_time?.slice(3, 5)) ?? 0,
            minutes: parseInt(subitem.need_time?.slice(6, 8)) ?? 0,
          },
          subtasks: subitem.subtasks?.length
            ? subitem.subtasks
                ?.map((parallelsubitem) => ({
                  ...parallelsubitem,
                  measurement: parallelsubitem.product?.measurement,
                  need_time: {
                    seconds: parseInt(parallelsubitem.need_time?.slice(0, 2)) ?? 0, // THESE ARE DAYS NOT SECONDS!!!
                    hours: parseInt(parallelsubitem.need_time?.slice(3, 5)) ?? 0,
                    minutes: parseInt(parallelsubitem.need_time?.slice(6, 8)) ?? 0,
                  },
                }))
                .sort((a, b) => a.order - b.order)
            : [],
        }))
        .sort((a, b) => a.order - b.order),
    }))
    .sort((a, b) => a.order - b.order);

  return deal;
};

const updateDealTasksById = async (id, data, total_time) => {
  const updatedData = data.map((item, index) => {
    const updatedSubtasks = item.subtasks.map((subtask, subIndex) => ({
      ...subtask,
      order: subIndex,
    }));

    return {
      ...item,
      order: index,
      subtasks: updatedSubtasks,
    };
  });

  const { data: response } = await axios.put(`/deals/${id}/tasks`, {
    total_time,
    task: updatedData,
  });

  return response;
};

const getDealStatuses = async () => {
  const { data: response } = await axios.get('/deals/statuses');
  return response.data;
};

const getDealFromBitrix = async () => {
  const { data: response } = await axios.post('/get-deal-from-bitrix');
  return response.data;
};

const addDealSubtask = async (data) => {
  const { data: response } = await axios.post(`/deals/${data.deal_id}/tasks/${data.task_id}`, {
    subtasks: [{ ...data }],
  });
  return response.data;
};

const putDealSubtask = async (data) => {
  const { data: response } = await axios.put(`/deals/${data.deal_id}/tasks/${data.task_id}`, data);
  return response.data;
};

const deleteDealSubtask = async (deal_id, task_id, subtask_id) => {
  const { data: response } = await axios.delete(
    `/deals/${deal_id}/tasks/${task_id}/subtask/${subtask_id}`,
  );
  return response.data;
};

const addDealSubtaskProducts = async (deal_id, task_id, subtask_id, data) => {
  const { data: response } = await axios.put(
    `/deals/${deal_id}/tasks/${task_id}/subtask/${subtask_id}`,
    {
      materials: data.materials.map((item) => ({
        ...item,
        measurement_id: item.measurement?.measurement_id_c,
      })),
      derivative_products: data.derivative_products.map((item) => ({
        ...item,
        measurement_id: item.measurement?.measurement_id_c,
      })),
    },
  );
  return response.data;
};

const getDealPlanList = async (id, page) => {
  const { data: response } = await axios.get(`/deals/${id}/plans`, {
    params: {
      page,
    },
  });
  return response;
};

const editDeal = async (data) => {
  const { data: response } = await axios.put(`/deals/${data.id}/status/${data.status?.id}`);
  return response;
};

const getTaskStage = async (taskId) => {
  const { data: response } = await axios.get(`/deals/${taskId}/progress`);
  return response;
};

export {
  getDeals,
  getDealById,
  updateDealTasksById,
  getDealStatuses,
  getDealFromBitrix,
  getDealPlanList,
  editDeal,
  addDealSubtask,
  addDealSubtaskProducts,
  putDealSubtask,
  deleteDealSubtask,
  getTaskStage,
};
