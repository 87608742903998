import { ref, watch } from 'vue';
import Swal from 'sweetalert2';

export default function useLoadingModal() {
  const isDataLoading = ref(false);
  const swalInstance = Swal;

  watch(isDataLoading, () => {
    const loginModal = localStorage.getItem('loginModal') == 'true' ? true : false;

    if (isDataLoading.value && !loginModal)
      swalInstance.fire({
        html: `
          <div class="main-alert">
              <span class="main-alert__title">Идёт загрузка, пожалуйста подождите</span>
              <div
                class="main-alert__spinner spinner-border text-warning"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </div>
          </div>
      `,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    else if (!loginModal) {
      swalInstance.close();
    }
  });

  return { isDataLoading };
}
