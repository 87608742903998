<template>
  <nav v-show="totalPages > 1" aria-label="page-navigation">
    <ul class="pagination justify-content-end my-3">
      <li class="page-item" :class="{ disabled: currentPage == 1 }">
        <button
          type="button"
          class="page-link"
          @click="$emit('update:currentPage', 1)"
        >
          &laquo;
        </button>
      </li>

      <li class="page-item" :class="{ disabled: currentPage == 1 }">
        <button
          type="button"
          class="page-link"
          @click="$emit('update:currentPage', currentPage - 1)"
        >
          &lsaquo;
        </button>
      </li>

      <li class="page-item disabled" v-if="totalPages > 5 && currentPage >= 4">
        <button type="button" class="page-link">...</button>
      </li>

      <li
        v-for="i in totalPagesList"
        :key="i"
        class="page-item"
        :class="{ active: currentPage == i }"
      >
        <button
          type="button"
          class="page-link"
          @click="$emit('update:currentPage', i)"
        >
          {{ i }}
        </button>
      </li>

      <li
        class="page-item disabled"
        v-if="totalPages > 5 && totalPages - currentPage > 2"
      >
        <button type="button" class="page-link">...</button>
      </li>

      <li class="page-item" :class="{ disabled: currentPage == totalPages }">
        <button
          type="button"
          class="page-link"
          @click="$emit('update:currentPage', currentPage + 1)"
        >
          &rsaquo;
        </button>
      </li>

      <li class="page-item" :class="{ disabled: currentPage == totalPages }">
        <button
          type="button"
          class="page-link"
          @click="$emit('update:currentPage', totalPages)"
        >
          &raquo;
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { ref, toRef, reactive, computed, watch } from "vue";

export default {
  props: ["currentPage", "totalPages"],
  emits: ["update:currentPage"],
  setup(props) {
    const state = reactive({});
    const totalPages = toRef(props, "totalPages");
    const totalPagesArray = ref(
      Array.from({ length: totalPages.value }, (_, i) => i + 1)
    );

    const totalPagesList = computed(() => {
      if (totalPages.value < 6) return totalPagesArray.value;
      else if (totalPages.value - props.currentPage <= 2)
        return totalPagesArray.value.slice(
          totalPages.value - 4,
          totalPages.value
        );
      else if (props.currentPage > 3)
        return totalPagesArray.value.slice(
          props.currentPage - 2,
          props.currentPage + 1
        );
      else return totalPagesArray.value.slice(0, 4);
    });

    watch(totalPages, () => {
      totalPagesArray.value = Array.from(
        { length: totalPages.value },
        (_, i) => i + 1
      );
    });

    return { state, totalPagesList };
  },
};
</script>


<style lang="scss" scoped>
.page-link.active,
.active > .page-link {
  background-color: var(--main-blue);
  border-color: var(--main-blue);
  color: var(--main-white);
}

.page-link {
  color: var(--main-blue);
}

.page-item.disabled {
  cursor: not-allowed;
}
.page-link.disabled,
.disabled > .page-link {
  color: var(--main-light-grey);
  background-color: var(--main-white);
}
</style>